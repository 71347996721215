import React, { useState, useEffect } from "react";
import star_icon from "../assets/images/star_icon.svg";
import { ethers } from "ethers";
import { data } from "./dumydata";
import axios from 'axios';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import HumanMindsets from '../assets/images/10_core_human_mindsets_2022.png'
import DivergentsArtifact from '../assets/minting/DI.json'
// import ApprovedAddresses from '../assets/minting/addresses.json'
global.Buffer = global.Buffer || require('buffer').Buffer


// const keccak256 = require('keccak256');
// const { MerkleTree } = require('merkletreejs');

const Hero = () => {

  // Goerli Contract Addresses:
  // Main Divergents contract: 0xA30Db6C618422c98BdEa627244d63e7737074748
  // DI contract: 0x88809d3d5a061E3C806247d96CaA950B399C1fc8
  // Crossmint minting contract(DICM): 0x61b972ca43b3D9d0a0Bfc5B529334E2F1C295f41

  const networkSwitch     = 'homestead'
  const divergentsAddress = networkSwitch === 'homestead' ? '0x86b919f8a5D94dD8622D30dDcB51FbFe721F22F0' : '0x88809d3d5a061E3C806247d96CaA950B399C1fc8' // Currently testnet Mainnet address:'0x4bc3f1b06Da13CaeCA89CFb91B799d0B5685C663'
  const divergentsChainId = networkSwitch === 'homestead' ? '0x1' : '0x5' // '0x5' for goerli, '0x1' for mainnet
  const divergentsNetwork = networkSwitch === 'homestead' ? 'homestead' : 'goerli' // 'goerli' for goerli, 'homestead' for mainnet
  const providerAccess    = networkSwitch === 'homestead' ? 'H3d3TT7HQz_ZcJl6BNNs1QsSJUDjOPy5' : 'hSQjL9eiIAt9-PXbYRLRHGi8dMkp97Za' // 'hSQjL9eiIAt9-PXbYRLRHGi8dMkp97Za' for goerli,  'H3d3TT7HQz_ZcJl6BNNs1QsSJUDjOPy5' for mainnet

  const metamaskInstalled = (typeof window.ethereum !== 'undefined')
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [characterSelection, setCharacterSelection] = useState([0,0,0,0,0,0,0,0,0,0])
  const [charactersRemaining, setCharactersRemaining] = useState([0,0,0,0,0,0,0,0,0,0])
  const [charactersSoldOut, setCharactersSoldOut] = useState([0,0,0,0,0,0,0,0,0,0])
  const [totalMinted, setTotalMinted] = useState(0)
  // const [totalMintedByUser, setTotalMintedByUser] = useState(0)
  // const [maxMintableByUser, setMaxMintableByUser] = useState(10)
  const [totalPrice, setTotalPrice] = useState(0)
  const [saleStatus, setSaleStatus] = useState('Closed')
  const [addressWhitelisted, setAddressWhitelisted] = useState(false)
  // const [userMerkleProof, setUserMerkleProof] = useState([])
  const [submittingMintTx, setSubmittingMintTx] = useState(false)

  const provider = new ethers.providers.AlchemyProvider(divergentsNetwork, providerAccess)
  const abi = DivergentsArtifact.abi

  const contract = new ethers.Contract(divergentsAddress, abi, provider)

  const whitelistedPrice = 0.05;
  const normalPrice = 0.06;
  const crossMintEnabled = false



  useEffect(() => {
    updateSaleMintingStatus()

  }, [selectedAddress])

  // useEffect(() => {
  //   whitelistedAddress()
  //   // maxMintableByUserCurrently()
  // }, [addressWhitelisted, saleStatus])

  useEffect(() => {
    totalMintCost()
  }, [characterSelection])

  useEffect(() => {
    totalMintCost()
  }, [addressWhitelisted])


  const connectToMetaMask = async () => {

    const userAddress = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const checksummedAddress = await ethers.utils.getAddress(userAddress[0])
    setSelectedAddress(checksummedAddress)
    const isAddressWhitelisted = await axios.get(`https://api.thedivergents.io/allowlist/${checksummedAddress}`)
    console.log(isAddressWhitelisted.data)

    if(isAddressWhitelisted.data === true) {
      await setAddressWhitelisted(addressWhitelisted => true)
      console.log('Address whitelisted')
    } else {
      console.log('Address not on Whitelist')
    }

    totalMintCost()


  }

  // const whitelistedAddress = async () => {
  //   if (addressWhitelisted && saleStatus === 'Whitelist') {

  //     const checksummedAddress = ethers.utils.getAddress(window.ethereum.selectedAddress)
  //     const generatedProof = await generateMerkleProof(checksummedAddress)
  //     // console.log(generatedProof)
  //     setUserMerkleProof(generatedProof)
  //   }
  // }

  // const maxMintableByUserCurrently = async () => {
  //   setMaxMintableByUser(10) // max mintable by user per character
  // }


  // Get sale status / availability and minted from contract
  const updateSaleMintingStatus = async () => {
    const getTotalSupply = (await contract.totalSupply()).toNumber()
    const getCharactersRemaining = await contract.charactersRemaining()
    const getPublicSaleStatus = await contract.isPublicSaleOpen()
    // const getWhitelistSaleStatus = await contract.isWhitelistSaleOpen()

    setTotalMinted(getTotalSupply)
    setCharactersRemaining(...[getCharactersRemaining])
    // console.log(getCharactersRemaining)
    const characterAvailability = await getCharactersRemaining.map(character => {return character === 0 ? 1 : 0})
    // console.log(characterAvailability)
    await setCharactersSoldOut(...[characterAvailability])
    // console.log(charactersSoldOut)


    // const totalMintableCharactersRemaining = getCharactersRemaining.reduce((a, b) => a + b)
    if (getPublicSaleStatus) {
      setSaleStatus('Public')
    } else {
      setSaleStatus('Closed')
    }

    // maxMintableByUserCurrently()

  }

  // Merkle Tree generator functions
  // const hashAccount = (userAddress) => {
  //   return Buffer.from(
  //     ethers.utils.solidityKeccak256(["address"],[userAddress]).slice(2),'hex'
  //   );
  // }

  // const generateMerkleTree = (addresses) => {
  //   const merkleTree = new MerkleTree(
  //     addresses.map(hashAccount),
  //     keccak256,
  //     {sortPairs: true}
  //   );
  //   // console.log(merkleTree.getHexRoot())
  //   return merkleTree;
  // }

  // const generateMerkleProof = (userAddress) => {
  //   const merkleTree = generateMerkleTree(ApprovedAddresses);
  //   const proof = merkleTree.getHexProof(
  //     hashAccount(userAddress));
  //   return proof;
  // }

  // Selection increment & decrement counter
  /*
  Rule:
  For increment:
  Total selection should not be more than max mint allowable
  For each item max item should be the lesser of the max available, max total mintable

  For decrement: Only need to check that item is not 0 (as you can't have negative selection)
  */
  const incSelection = (index) => {
    let characterSelectionArray = characterSelection
    // let totalSelected =  characterSelectionArray.reduce((a, b) => a + b)
    if (characterSelectionArray[index] < 10) {
      if(characterSelectionArray[index] < charactersRemaining[index]) {
        characterSelectionArray[index] = characterSelectionArray[index] + 1
        setCharacterSelection([...characterSelectionArray])
        console.log(characterSelection)
      }
    }
  }


  const decSelection = (index) => {
    let characterSelectionArray = characterSelection
    if(characterSelectionArray[index] > 0) {
      characterSelectionArray[index] = characterSelectionArray[index] - 1
      setCharacterSelection([...characterSelectionArray])
      console.log(characterSelection)
    }
  }

  // Calculate total
  const totalMintCost = () => {
    let totalSelected = characterSelection.reduce((a, b) => a + b)
    if (addressWhitelisted) {
      setTotalPrice ( totalPrice => whitelistedPrice * totalSelected)
    } else {
      setTotalPrice ( totalPrice => normalPrice * totalSelected)
    }

  }


  // Minting functions

//   const whitelistMint = async () => {
//
//     await window.ethereum.request({ method: 'eth_requestAccounts' })
//     await window.ethereum.request({
//         method: 'wallet_switchEthereumChain',
//         params: [{ chainId: divergentsChainId }]
//     })
//     await window.ethereum.request({ method: 'eth_requestAccounts' })
//
//     if(submittingMintTx === true) {
//       alert ('Another pending transaction already submitted. Please wait for transaction to complete or reload page to try again')
//     }
//
//     let totalSelected =  characterSelection.reduce((a, b) => a + b)
//
//     setSubmittingMintTx(true)
//     try {
//         const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
//         const signer = provider.getSigner()
//         const contractMint = new ethers.Contract(divergentsAddress, abi, signer)
//
//         const tx = await contractMint.whitelistMint(
//           characterSelection,
//           userMerkleProof,
//           { value: ethers.utils.parseUnits((totalSelected * 100000000).toString(), 'gwei') }
//       )
//
//         alert(`Transaction submitted, please check your metamask wallet for status progress`)
//
//         tx.wait().then(receipt => {
//             // console.log(receipt)
//             alert(`Transaction hash ${receipt.transactionHash} has been verified`)
//         })
//     } catch (err) {
//         alert(err.message)
//     } finally {
//         setSubmittingMintTx(false)
//     }
//
// }

  const publictMint = async () => {

    await window.ethereum.request({ method: 'eth_requestAccounts' })
    await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: divergentsChainId }]
    })
    await window.ethereum.request({ method: 'eth_requestAccounts' })

    if(submittingMintTx === true) {
      alert ('Another pending transaction already submitted. Please wait for transaction to complete or reload page to try again')
    }

    // let totalSelected =  characterSelection.reduce((a, b) => a + b)

    setSubmittingMintTx(true)
    try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
        const signer = provider.getSigner()
        const contractMint = new ethers.Contract(divergentsAddress, abi, signer)

        let whitelistingBool = addressWhitelisted ? 'true' : 'false'


        const tx = await contractMint.saleMint(
          characterSelection, whitelistingBool,
          { value: ethers.utils.parseUnits(totalPrice.toString(), 'ether') }
      )

        alert(`Transaction submitted, please check your metamask wallet for status progress`)

        tx.wait().then(receipt => {
            // console.log(receipt)
            alert(`Transaction hash ${receipt.transactionHash} has been verified`)
        })
    } catch (err) {
        alert(err.message)
    } finally {
        setSubmittingMintTx(false)
    }

  }

 // Call mint (this function decides which mint function to call)
  const callMint = () => {
      publictMint()
  }




  return (
    <div className=" Hero mt-10 xl:-mt-20 xl:grid xl:grid-cols-2 px-5">
      <div className="relative xl:ml-[7rem] mx-auto">
        <img
          src={star_icon}
          alt="star_icon"
          className="absolute w-20 top-[20px] left-[250px] md:w-32 md:top-[5px] md:left-[400px] xl:top-[20px] xl:left-[425px] 2xl:w-40 2xl:left-[600px] " //absolute top-[11rem] left-[15rem] text-divergent-perfume   sm:w-44  sm:flex sm:absolute sm:top-[-87px] sm:left-[380px]
        />
        <p className="text-divergent-green xl:text-lg"> {/*  ml-1 sm:ml-0 text-[0.7rem] w-[22rem] text-center xl:text-justify mt-3 sm:text-[15px] sm:w-[85%] 2xl:text-[23px] */}
          MINT <br />
        </p>
        <h6 className="text-divergent-green font-FaroTrial text-4xl 2xl:text-[5.25rem] md:text-6xl pb-6"> {/* //  */}
        The Divergents {/* The Divergents <br /> 2022 NFTs */}
        </h6>
        
        <p className="text-divergent-lightgray xl:text-left pb-2 leading-relaxed max-w-prose"> {/*  ml-1 sm:ml-0 text-[0.7rem] w-[22rem] text-center xl:text-justify mt-3 sm:text-[15px] sm:w-[85%] 2xl:text-[23px] */}
        Join the tribe of
          <span className="text-divergent-green font-FaroTrial"> 2022 unique NFTs by artist Marek Ehrenberger! </span><br />
          Choose the best <a href="https://thedivergents.io/#characters" target="_blank" rel="noreferrer" className="text-divergent-perfume underline">Divergent character</a> based on your looks or mindset, connect your wallet & mint up to 10 NFTs per transaction. Or simply buy it with your credit card (maximum 5 NFTs). <br />
        </p>
        {/* <h5 className="text-divergent-lightgray leading-relaxed pt-8 pb-6">
          <ul>
            <li><b>MINT OPENS ON 28 JUNE 2022</b></li>
            <li><span className="text-divergent-green">✦ WL mint ✦</span> 7-9 p.m. CET / 5-7 p.m. UTC</li>
            <li><span className="text-divergent-green">✦ Public mint ✦</span> from 9 p.m. CET / 7 p.m. UTC</li>
            <li>✦ Max 10 NFTs per Tx</li>
          </ul>
        </h5> */}

        {/* <h5 className="text-divergent-lightgray leading-relaxed pt-8 pb-6">
          <ul>
            <li><span className="text-divergent-green ">✦ 2+ NFTs = free exclusive print of your favored NFT signed by the artist</span></li>
            <li>✦ Multiple NFTs minting for 1 gas fee as ERC-721A</li>
            <li>✦ 8 more IRL & web3 benefits for holders in Road Map</li>
            <li>✦ Max 10 NFTs per Tx</li>
          </ul>
        </h5> */}
        <p className="pb-5 text-divergent-lightgray"><b>Limited time, limited opportunity to mint your favorite Divergent! </b></p>
        <img
          src={HumanMindsets}
          alt="Human Mindsets"
          className=""
        />
        <p className="text-divergent-perfume text-lg mt-8">
          PRICE: {addressWhitelisted ? 0.05 : 0.06} ETH
        </p>
        <p className="text-divergent-perfume text-3xl xl:text-4xl font-bold font-FaroTrial">
          MINTED {totalMinted}/2022
        </p>
        <p className="hidden lg:block text-divergent-lightgray pt-2 text-sm">
        Join our community on <a href="http://discord.gg/thedivergents" className="text-divergent-perfume">Discord</a> to discover holder benefits and our current plans & long-term vision. Reveal of the NFTs on Sunday. 

        </p>


      </div>
      <div className="Hero__rightside relative pt-12 xl:mt-10 xl:ml-[10rem] xl:mr-[7rem] lg:relative lg:top-0 flex flex-col lg:p-0 gap-[0.63rem] lg:flex lg:flex-col lg:gap-[0.63rem] 2xl:mr-[150px]">


        {data.map((item, index) => {
          if (charactersSoldOut[index] === 0) {
            return (
              <>
                <div key={index} className="flex justify-between my-auto">
                  <div className="text my-auto">
                    <h3 className="text-divergent-lightgray font-bold font-FaroTrial text-md xl:text-lg 2xl:text-2xl my-auto">
                      {item.title}
                    </h3>
                  </div>
                  <div disabled={!selectedAddress || charactersRemaining[index] === 0 } className="count flex gap-6 disabled:text-divergent-black">
                    <button onClick={() => decSelection(index)} className="text-divergent-lightgray font-bold text-md xl:text-lg 2xl:text-2xl my-auto">
                      -
                    </button>
                    <p className="text-divergent-lightgray font-bold text-md xl:text-lg 2xl:text-2xl my-auto">
                      {characterSelection[index]}
                    </p>
                    <button onClick={() => incSelection(index)} className="text-divergent-lightgray font-bold text-md xl:text-lg 2xl:text-2xl my-auto">
                      +
                    </button>
                  </div>
                </div>
                <div className="bg-divergent-green flex justify-around w-full h-px"></div>
              </>
            );
          } else {
            return (
              <>
                <div key={index} className="flex justify-between items-center">
                  <div className="">
                    <h3 className="text-gray-700 font-bold font-FaroTrial text-md xl:text-lg 2xl:text-2xl">
                      {item.title}
                    </h3>
                  </div>
                  <div className="count flex gap-6">

                    <p className="text-divergent-green text-md xl:text-lg 2xl:text-2xl">
                      SOLD OUT
                    </p>

                  </div>
                </div>
                <div className="bg-divergent-green flex justify-around w-full h-px"></div>
              </>
            );
          }

        })}


        <div className="text-divergent-green flex flex-col-2 place-content-between pt-12 md:px-56 xl:place-content-evenly xl:px-0 xl">
            {/* text-divergent-green relative ml-1 xl:ml-0 top-[37rem] xl:relative sm:top-0 flex flex-col justify-center items-center xl:justify-start gap-4 xl:mb-10 xl:flex xl:flex-row xl:gap-20 mt-16 mb-4 xl:pl-10" */}
          <div>
            <p >
              TOTAL: {totalPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} ETH

            </p>

          </div>
          {/* <div>
            <p className="underlined">
              <a href="https://uploads-ssl.webflow.com/61f3068fa15082695d30bb43/6214fcf7bb54f127a1ed0cbd_TheDivergents_final_Guide.pdf"><u>MINTING GUIDE</u></a>
            </p>

          </div> */}
        </div>

          <div className="flex flex-col gap-4 md:items-center lg:flex-row lg:justify-center">
          {/* diverge__button relative ml-1 xl:ml-0 top-[37rem] xl:relative sm:top-0 flex flex-col  gap-4 justify-center items-center xl:justify-start xl:mb-10 xl:flex xl:flex-row xl:gap-4 */}
            <button disabled={saleStatus === 'Closed' || !selectedAddress} onClick={callMint}
                    className="disabled:bg-divergent-black disabled:border bg-divergent-green md:w-80 h-14 font-bold font-FaroTrial hover:bg-divergent-perfume transition duration-0 hover:duration-700">
              MINT
            </button>
            <button onClick={connectToMetaMask}
                    className="truncate bg-divergent-black text-divergent-green border-solid border-2 border-divergent-green md:w-80 h-14 font-bold font-FaroTrial hover:bg-divergent-perfume hover:text-divergent-black hover:border-none transition duration-0 hover:duration-700 px-2">
              {!metamaskInstalled && `MetaMask Not Found`}
              {metamaskInstalled && !selectedAddress && `CONNECT WALLET`}
              {metamaskInstalled && selectedAddress &&
              <>
                  {selectedAddress}
              </>
              }
            </button>


            <p className="lg:hidden text-divergent-green text-center pt-2 text-sm underline">
              <a href="https://metamask.app.link/dapp/secret-mint.thedivergents.io" target='_blank' rel="noreferrer">Connect Metamask or Download Metamask for iOS/Android and access via browser in Metamask app to mint on mobile</a>
            </p>

            <p className="lg:hidden text-divergent-lightgray text-center pt-2 text-sm">
              NFTs reveal after the public mint.
            </p>


          </div>
          <div className="flex flex-col gap-4 md:items-center lg:flex-row lg:justify-center">

            <button className="" disabled={saleStatus === 'Closed' || totalPrice === 0 || totalPrice > 0.3}>
              <CrossmintPayButton 

                clientId="422e7ec6-06b4-46ca-9703-80838e124184" // for testnet:5f93fb2c-642f-48ef-81ce-753b4eeb8742 for mainnet: 422e7ec6-06b4-46ca-9703-80838e124184
                // environment= "https://goerli.crossmint.io"

                mintConfig={{"type":"erc-721","totalPrice":`${totalPrice}`,"_mintList":characterSelection}}

              />
              {/* {totalPrice===0 && `SELECT UP TO 5 NFTS TO BUY WITH CREDIT CARD`}
              {totalPrice!=0 && totalPrice <=0.3 && 
                
            
              } */}
            </button>

            
            
            
          </div>

        </div>

    </div>
  );
};

export default Hero;

export const data = [
  {
    id: "1",
    title: "The Artist",
  },
  {
    id: "2",
    title: "The Animal Spirit",
  },
  {
    id: "3",
    title: "The Digital Alchemist",
  },
  {
    id: "4",
    title: "The Environmentalist",
  },
  {
    id: "5",
    title: "The Good Samaritan",
  },
  {
    id: "6",
    title: "The Equality Defender",
  },
  {
    id: "7",
    title: "The Mindful Master",
  },
  {
    id: "8",
    title: "The Thinker",
  },
  {
    id: "9",
    title: "The Inventor",
  },
  {
    id: "10",
    title: "The Entrepreneur",
  },
];

import React from "react";
// import logo from "../assets/images/logo.svg";
import logosvg from "../assets/images/logo.svg";
// import n_icon from "../assets/images/n_icon.svg";
// import { FaTwitter, FaTelegramPlane, FaDiscord, FaNeos } from "react-icons/fa";
const Navbar = () => {
  return (
    <div className="nav__header flex justify-between sm:mb-[8%]  mt-7 items-center">
      <div className="nav__logo ml-5 absolute xl:fixed">
        <img
          src={logosvg}
          alt="logo"
          className="cursor-pointer sm:mt-14 w-[40px]  sm:w-[69.66px] sm:h-[83.73px]" //w-[40px]  sm:w-[69.66px] sm:h-[83.73px]
        />
      </div>
      <ul className="  sm:mr-7  nav__menu flex ml-[88%] gap-10 2xl:ml-[90%]">
        <li className="nav__item text-divergent-lightgray text-lg hover:text-divergent-green cursor-pointer sm:mr-0">
          <a href="https://thedivergents.io/">BACK</a>
        </li>
        {/* <li className="nav__item hidden xl:block">
          <ul className="nested__menu hidden flex-col  gap-3  sm:flex fixed">
            <li className="nested__item pb-3 mx-auto">
              <a href="https://discord.com/invite/thedivergents" target="_blank">
                <svg id="Component_9_4_Hover_State_" data-name="Component 9 – 4 (Hover State)" xmlns="http://www.w3.org/2000/svg" width="30.161" height="21.537" viewBox="0 0 30.161 21.537" className="fill-white hover:fill-divergent-green duration-150">
                  <path id="Path_24" data-name="Path 24"
                        d="M2517.637,1027.87l-.362.413a16.972,16.972,0,0,1,6.444,3.252,21.485,21.485,0,0,0-7.78-2.456,21.85,21.85,0,0,0-5.221.051,2.57,2.57,0,0,0-.44.052,20.25,20.25,0,0,0-5.868,1.628c-.955.413-1.524.724-1.524.724a16.957,16.957,0,0,1,6.771-3.36l-.259-.31a12.816,12.816,0,0,0-7.242,2.69,34.129,34.129,0,0,0-3.722,14.94,9.386,9.386,0,0,0,7.882,3.9s.956-1.138,1.731-2.12a8.079,8.079,0,0,1-4.524-3.024s.258.181.724.44c.026,0,.052.026.1.051.077.053.156.079.232.13a15.212,15.212,0,0,0,1.887.879,19.689,19.689,0,0,0,3.8,1.112,18.118,18.118,0,0,0,6.692.026,18.55,18.55,0,0,0,3.747-1.112,14.793,14.793,0,0,0,2.973-1.525,8.192,8.192,0,0,1-4.678,3.05c.777.955,1.708,2.067,1.708,2.067v.005a9.473,9.473,0,0,0,7.884-3.877,34.134,34.134,0,0,0-3.722-14.94A12.758,12.758,0,0,0,2517.637,1027.87Zm-6.339,13.857a2.848,2.848,0,1,1-2.629-3.051h.027a2.731,2.731,0,0,1,2.611,2.846C2511.306,1041.592,2511.3,1041.66,2511.3,1041.728Zm9.434,0a2.848,2.848,0,1,1-2.629-3.051h.026a2.731,2.731,0,0,1,2.61,2.846C2520.74,1041.592,2520.737,1041.66,2520.732,1041.728Z"
                        transform="translate(-2498.436 -1027.863)"  />
                </svg>
              </a>
            </li>
            <li className="nested__item pb-3 mx-auto">
              <a href="https://twitter.com/DivergentsNFT" target="_blank">
                <svg className="fill-white hover:fill-divergent-green duration-150" id="Icon_awesome-twitter_Hover_State_" data-name="Icon awesome-twitter (Hover State)" xmlns="http://www.w3.org/2000/svg" width="28.153" height="22.866" viewBox="0 0 28.153 22.866">
                  <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" 
                        d="M25.259,9.079c.018.25.018.5.018.75,0,7.628-5.806,16.417-16.417,16.417A16.305,16.305,0,0,1,0,23.656a11.936,11.936,0,0,0,1.393.071,11.556,11.556,0,0,0,7.163-2.465,5.78,5.78,0,0,1-5.395-4,7.277,7.277,0,0,0,1.09.089,6.1,6.1,0,0,0,1.518-.2,5.771,5.771,0,0,1-4.627-5.663v-.071a5.811,5.811,0,0,0,2.608.732A5.779,5.779,0,0,1,1.965,4.435a16.4,16.4,0,0,0,11.9,6.038,6.514,6.514,0,0,1-.143-1.322A5.776,5.776,0,0,1,23.705,5.2a11.36,11.36,0,0,0,3.662-1.393,5.754,5.754,0,0,1-2.537,3.18A11.567,11.567,0,0,0,28.153,6.1a12.4,12.4,0,0,1-2.894,2.983Z"
                        transform="translate(0 -3.381)"   />
                </svg>
              </a> 
            </li>
            <li className="nested__item pb-3 mx-auto">
              <a href="https://bit.ly/DivergentsNFT" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="27.274" height="22.874" viewBox="0 0 27.274 22.874" className="fill-white hover:fill-divergent-green duration-150">
                  <g id="Icon_awesome-telegram-plane_Hover_State_" data-name="Icon awesome-telegram-plane (Hover State)" transform="translate(0 0)" >
                    <path id="Icon_awesome-telegram-plane" data-name="Icon awesome-telegram-plane"
                          d="M27.2,6.61l-4.116,19.41c-.311,1.37-1.12,1.711-2.271,1.065L14.54,22.465l-3.026,2.91a1.575,1.575,0,0,1-1.26.615L10.7,19.6,22.327,9.1c.505-.451-.11-.7-.785-.25L7.172,17.9.987,15.962c-1.346-.42-1.37-1.346.28-1.991l24.2-9.322C26.583,4.23,27.563,4.9,27.2,6.61Z"
                          transform="translate(-0.001 -4.528)" />
                  </g>
                </svg>
              </a>
            </li>
            <li className="nested__item pb-3 mx-auto">
              <a href="http://www.nftense.com" target="_blank">
                <svg className="fill-white hover:fill-divergent-green duration-150" id="Component_12_3_Hover_State_" data-name="Component 12 – 3 (Hover State)" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="22.282" height="23.348" viewBox="0 0 22.282 23.348">
                  <defs>
                    <clipPath id="clipPath">
                      <rect id="Rectangle_235" data-name="Rectangle 235" width="22.282" height="23.347" fill="#39ffa0"/>
                    </clipPath>
                  </defs>
                  <g id="Group_248" data-name="Group 248" clipPath="url(#clip-path) " >
                    <path id="Path_523" data-name="Path 523" d="M18.947,11.675v6.546L3.335,0H0V8.339a3.335,3.335,0,0,0,3.335,3.335V5.128l15.612,18.22h3.335V15.01a3.335,3.335,0,0,0-3.335-3.335" transform="translate(0 -0.001)" />
                    <path id="Path_524" data-name="Path 524" d="M172.9,0V8.338a3.335,3.335,0,0,0,3.335,3.335V0Z" transform="translate(-157.291)" />
                    <path id="Path_525" data-name="Path 525" d="M40.274,140.963v-8.338a3.335,3.335,0,0,0-3.335-3.335v11.674Z" transform="translate(-33.604 -117.615)" />
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </li> */}
      </ul>
    </div>
  );
};

export default Navbar;

import "./App.css";
import Footer from "./components/Footer";
import Hero from "./components/Hero3";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <Hero />
      <Footer />
    </div>
  );
}

export default App;
